import { AnyAction } from "redux";

// action creator
import { actionCreator } from "./actionCreator/actionCreator";

// types
import { types } from './actionTypes/actionTypes';


export const sidebarTabChange = (tab: any): AnyAction => {
    return actionCreator(types.sidebarTabChange, tab);
}

export const sidebarSetDateFilter = (date: string): AnyAction => {
    return actionCreator(types.sidebarSetDateFilter, date);
}

export const sidebarLogout = (): AnyAction => {
    return actionCreator(types.sidebarLogout)
}