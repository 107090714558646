import { useDispatch } from 'react-redux';

// mui
import { useTheme } from '@mui/material/styles';
import { MenuItem, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// actions
import { articlesLogout } from '../../../../../actions/articles.actions';
import { cleanFilesList } from '../../../../../actions/files.actions';
import { configLogout } from '../../../../../actions/config.actions';
import { partsLogout } from '../../../../../actions/parts.actions';
import { snackBarLogout } from '../../../../../actions/snackBar.actions';
import { soundEffectsLogout } from '../../../../../actions/soundEffects.actions';
import { startLogout } from '../../../../../actions/auth.actions';
import { startLogoutUser } from '../../../../../actions/users.actions';
import { voicesLogout } from '../../../../../actions/voices.actions';
import { sidebarLogout } from '../../../../../actions/sidebar.actions';

interface Props {
    buttonType?: 'button' | 'menuItem';
}

export const LogoutButton = ({ buttonType = 'button' }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(startLogout());
        dispatch(cleanFilesList());
        dispatch(articlesLogout());
        dispatch(configLogout());
        dispatch(partsLogout());
        dispatch(soundEffectsLogout());
        dispatch(voicesLogout());
        dispatch(sidebarLogout());
        dispatch(snackBarLogout());
        dispatch(startLogoutUser());
    };

    return (
        <>
            {buttonType === 'button' && (
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<ExitToAppIcon />}
                    style={{ height: 30, maxWidth: 110 }}
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            )}
            {buttonType === 'menuItem' && (
                <MenuItem key={`config-menu-logout`} onClick={handleLogout} sx={{ pl: 3, py: 1.5 }}>
                    <Stack direction="row" spacing={2}>
                        <ExitToAppIcon htmlColor={theme.palette.secondary.main} />
                        <Typography textAlign="center" color={theme.palette.secondary.main}>
                            Logout
                        </Typography>
                    </Stack>
                </MenuItem>
            )}
        </>
    );
};
